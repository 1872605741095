exports.TRANSLATION_STATUS = {
  MISSING: 'missing',
  OUTDATED: 'outdated',
  COPIED: 'copied',
  OBSOLETE: 'obsolete',
  UP_TO_DATE: 'up-to-date',
  IGNORED: 'ignored'
}

exports.PATHS = {
  LOCAL_LANG_FOLDER_NAME: 'translation-files',
}