/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { ThemeProvider, Container, Flex, Heading } from 'theme-ui'
import theme from '@makerdao/dai-ui-theme-maker-neue'
import Helmet from "react-helmet"

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    background: '#fbfbfb'
  }
}

const MainLayout = ({ children, buildDate: buildDateString }) => {
  const buildDate = new Date(buildDateString)
  return (
    <ThemeProvider theme={customTheme}>
      <Helmet title="Translate Maker" >
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      <Container sx={{pb: '80px'}}>
        <Flex variant="flex.header" sx={{py: 2}}>
          <Heading><Link style={{color: '#434358', textDecoration: 'none'}} to="/">Translate Maker</Link></Heading>
        </Flex>
        <div>Last Updated: {buildDate.toDateString()} - {buildDate.toLocaleTimeString()}</div>
        <main style={{marginTop: '30px'}}>{children}</main>
      </Container>
    </ThemeProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
