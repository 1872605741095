const flat = require("flat")
const slugify = require("slugify")
const { TRANSLATION_STATUS, PATHS } = require("./constants")
const countBy = require("lodash.countby")

exports.getStatusesCount = function getStatusesCount(translationMeta) {
  const allStatus = Object.values(TRANSLATION_STATUS).filter(status => status !== TRANSLATION_STATUS.IGNORED)
  const statusCount = countBy(translationMeta, 'status')
  return allStatus.map(status => ({
    status,
    count: statusCount[status] || 0
  }))
}

exports.pathToSlug = path => path.replace(/\//g, '-')

exports.getLangTranslationFilePath = ({ repo, branch }, langName, langPath) => `${exports.pathToSlug(`${repo}/${branch}`)}/${langName}/${exports.pathToSlug(langPath)}`

exports.getLangLocalFileUrl = (...args) =>
  `/${PATHS.LOCAL_LANG_FOLDER_NAME}/${exports.getLangTranslationFilePath(...args)}`

exports.getStatsPageUrl = (site, contentName, lang) => `/${site.repo}/${site.branch}/${slugify(contentName)}/${lang}`

exports.matchesSiteAndContent = (a, b) => 
  a.site.name === b.site.name &&
  a.site.repo === b.site.repo &&
  a.site.branch === b.site.branch &&
  a.content.name === b.content.name


const traverse = function(o, fn) {
  for (let i in o) {
    if (o.hasOwnProperty(i)) {
      fn.apply(this, [i, o[i], o]);
      if (o[i] !== null && typeof o[i] === "object") {
        traverse(o[i], fn);
      }
    }
  }
}

function convertArraysToObjects(obj) {
  traverse(obj, (key, value, owner) => {
    if (Array.isArray(value)) {
      owner[key] = removeFalsy({...value})
    }
  })
}
// exported for testing only
exports.convertArraysToObjects = convertArraysToObjects

function removeFalsy(original) {
  const noFalsy = {...original}
  Object.keys(noFalsy)
    .forEach((k) => !noFalsy[k] && delete noFalsy[k]);
  return noFalsy
}
exports.removeFalsy = removeFalsy

exports.getEditedFileString = function getEditedFileString(original, edited) {
  const modifiedMessages = Object.assign({} , original, removeFalsy(edited))
  const unflattened = flat.unflatten(removeFalsy(modifiedMessages))
  convertArraysToObjects(unflattened)
  return JSON.stringify(unflattened, null, 2)
}
